import axios from 'axios';
import { getAPIAuthKey } from '../apiconfig';
import * as apis from './api_urls';

const ids = [
  133, 408, 1066, 1860, 1859, 1861, 1862, 1863, 1478, 1541, 1022, 93, 94, 1836,
  1717, 1837, 1838, 1839, 1840, 1857, 1841, 1842, 1843, 1844, 1845, 1846, 1858,
  1859, 46, 1847, 1848, 716, 717, 1354, 1849, 1850, 1851, 1852, 1853, 1854,
  1855, 1856, 674, 1912, 1913, 1914, 177, 51, 1910, 1911, 1692, 180, 1918, 1305,
  1921, 54, 1565, 1922, 1923, 1517, 1927, 1930, 1420, 212, 1919, 355, 58, 72,
  1932, 1973, 1985, 621, 705, 1351, 1009, 1991, 1224, 243, 1972, 1915, 1916,
  1917, 1024, 1698, 1413, 1414, 1710, 2167, 2168, 2169, 2170, 2171, 2172, 2173,
  2174, 2175, 2176, 2177, 2178, 2179, 2180, 2181, 2182, 2183, 2184, 2185, 2186,
  2187, 2188, 2189, 2190, 2191, 2192, 2193, 2194, 2195, 2196, 2197, 2198, 2199,
  2200, 2201, 2202, 2203, 2204, 2205, 2206, 2207, 2208, 2209, 791, 2069, 2212,
  2213, 2214, 2215, 2216, 2217, 2218, 2219, 2220, 2221, 2222, 2224, 2225, 2226,
  2227, 2228, 2229, 2230, 2231, 2232, 2233, 2234, 2235, 2237, 2238, 2239, 2240,
  2241, 1088, 617, 67, 521, 2090, 61, 62, 1075, 2177, 1064, 386, 547, 1162, 676,
  404, 2040, 2223, 1167, 228, 1008, 198, 3342, 3343, 3356, 3344, 3345, 3347,
  3346, 3348, 3349, 3350, 3351, 3352, 3353, 3354, 3355, 264, 1889, 1350, 3720, 
  941, 218, 2112, 1516, 83, 1006, 1420, 1153, 912, 1529, 1061, 912, 2992,
  25, 2101, 684, 555, 1153, 1510, 99, 705, 1784, 66, 85, 1688, 1867, 1866, 684, 
  1793, 24, 710, 3721, 3726, 717, 1517, 1213, 621, 1738, 967, 1037, 742, 1154

];

var translations = null;
var divisionNames = null;

/**
 * Set Translations as per locale
 * @param {string} locale
 */
export function setTranslations(callingClass, locale) {
  axios(apis.getTranslationUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    },
    data: {
      translations: {
        ids: ids.join(','),
        locales: locale,
      },
    },
  })
    .then((result) => {
      let response = result.data;
      //console.log(response);
      translations = response;
      callingClass.translationCallback(locale);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTranslation(id, defaultWord) {
  if (translations !== null) {
    if (translations[id] !== undefined) {
      if (
        translations[id][Object.keys(translations[id])[0]] !== undefined &&
        translations[id][Object.keys(translations[id])[0]] !== ''
      ) {
        var parser = new DOMParser();
        return parser.parseFromString(
          translations[id][Object.keys(translations[id])[0]],
          'text/html'
        ).body.textContent;
      } else return defaultWord;
    } else {
      return defaultWord;
    }
  } else {
    return defaultWord;
  }
}

/**
 * Set Division Id as per the name
 *  * @param {string} locale
 */
/*export function setDivision(auth){
  
  axios(apis.getDivisionUrl(),{
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(
        (result)=>{
          let response = result.data;
          divisionNames = response;
        }).catch(
          (error)=>{
            console.log(error);
          }
        );}


        export function getDivisionName(id){
          if(divisionNames!==null){
              if(divisionNames[id]!==undefined){
                if((divisionNames[id]!=='' && divisionNames[id]!==undefined)){ 
                  return id+' - '+divisionNames[id];
                }
                else
                  return id;
              }else{
                  return id;
              }
          }else{
              return id;
          }
      } */
