import React from 'react';
import { getNewDomain } from '../../apiconfig';
let home = getNewDomain();
export default function Breadcrumb() {
  return (
    <div>
      <div className='container-fluid'>
        <div className='ph-header-main__title row px-md-4 px-lg-3'>
          <div className='col'>
            <h1 className='p-title'>Support </h1>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 '>
          <div className='col'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item' aria-current='page'>
                  <a href={`${home}/us/en/dashboard?cid=all`}>
                    Home
                  </a>
                </li>
                <li className='breadcrumb-item' aria-current='page'>
                  Support
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
