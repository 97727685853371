//import { AppEnv } from "./properties";
//import { TYPE_APP, TYPE_DOMAIN } from "./services/constants";

export function getHost() {
  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return `https://apidev.parker.com/dev/delegatedadmin`;
    //return `https://phtest.us.parker.corp/wcs/resources`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    // return `https://phtest.us.parker.corp/wcs/resources`;
    return `https://apidev.parker.com/test/delegatedadmin`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `https://api.parker.com/stage/delegatedadmin`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `https://api.parker.com/prod/delegatedadmin`;
  } else return `https://apidev.parker.com/test/delegatedadmin`;
}

export function getHostForDA() {
  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return `https://apidev.parker.com/dev`;
    //return `https://phtest.us.parker.corp/wcs/resources`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    // return `https://phtest.us.parker.corp/wcs/resources`;
    return `https://apidev.parker.com/test`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `https://api.parker.com/stage`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `https://api.parker.com/prod`;
  } else return `https://apidev.parker.com/test`;
}

export function getHostForTokenMangement() {
  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return `https://apidev.parker.com/dev`;
    //return `https://phtest.us.parker.corp/wcs/resources`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    // return `https://phtest.us.parker.corp/wcs/resources`;
    return `https://apidev.parker.com/test`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `https://api.parker.com/stage`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `https://api.parker.com`;
  } else return `https://apidev.parker.com/test`;
}

export function getAPIAuthKey() {
  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `b21ca5a56a8a470bb23bb8efa5c70abf`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `b21ca5a56a8a470bb23bb8efa5c70abf`;
  } else return `b21ca5a56a8a470bb23bb8efa5c70abf`;
}

export function getHeaderSubKey() {
  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return `ddbb33d6aec24ea1b11a1265123475d9`;
    //return `36417fe0db274983b867e8b080c1ff02`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return `36417fe0db274983b867e8b080c1ff02`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `e91ac95d27fc4c54b29ee5084d921fd7`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `a060321b2e524d95957cfc67f953d458`;
  } else return `a060321b2e524d95957cfc67f953d458`;
}
//Setting the API Keys For ApplicationBAsed Role Access
export function getHeaderSubKeyForApplicationRole() {
  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return `ddbb33d6aec24ea1b11a1265123475d9`;
    //return `36417fe0db274983b867e8b080c1ff02`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return `d152a1b2df3343df870bd7d81df4124f`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `a7c2431348e34bc1a6b79308c085a988`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `b21ca5a56a8a470bb23bb8efa5c70abf`;
  } else return `b21ca5a56a8a470bb23bb8efa5c70abf`;
}

export function getCSVTamplateUrl() {
  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    //return 'https://phtest.parker.com/wcsstore/MyParkerStorefrontAssetStore/assets/templates/User_CSV_Sample.csv'
    return '/assets/templates/User_CSV_Sample.csv';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    //return 'https://phtest.parker.com/wcsstore/MyParkerStorefrontAssetStore/assets/templates/User_CSV_Sample.csv'
    return '/assets/templates/User_CSV_Sample.csv';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    //return 'https://phstage.parker.com/wcsstore/MyParkerStorefrontAssetStore/assets/templates/User_CSV_Sample.csv'
    return '/assets/templates/User_CSV_Sample.csv';
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    // return 'https://ph.parker.com/wcsstore/MyParkerStorefrontAssetStore/assets/templates/User_CSV_Sample.csv'
    return '/assets/templates/User_CSV_Sample.csv';
  } else {
    //return 'https://ph.parker.com/wcsstore/MyParkerStorefrontAssetStore/assets/templates/User_CSV_Sample.csv'
    return '/assets/templates/User_CSV_Sample.csv';
  }
}
export function getExternalHost() {
  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return `http://dev.parker.com/`;
    //return `https://phtest.us.parker.corp/wcs/resources`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    // return `https://phtest.us.parker.corp/wcs/resources`;
    return `https://test.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `https://preview.parker.com/`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `https://www.parker.com/`;
  } else return `https://www.parker.com/`;
}

export function getNewDomain() {
  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return `http://devmy.parker.com/`;
    //return `https://phtest.us.parker.corp/wcs/resources`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    // return `https://phtest.us.parker.corp/wcs/resources`;
    return `https://testmy.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `https://stagemy.parker.com/`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `https://my.parker.com/`;
  } else return `https://my.parker.com/`;
}

export function getEnvDetail() {
  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return 'dev';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    return 'test';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return 'stage';
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return 'prod';
  } else {
    return 'dev';
  }
}

export function getPhEnvDetail() {
  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return 'phdev';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return 'phtest';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return 'phstage';
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return 'ph';
  } else {
    return 'phdev';
  }
}

export function getEnvForSearch() {
  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return 'dev';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    return 'test';
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return 'preview';
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return 'prod';
  } else {
    return 'dev';
  }
}
