import React, { useState } from 'react';
import * as translationUtils from '../../../common-utils/translation_utils';

const Pagination = ({  currentPage, postsPerPage, totalPosts, paginate, resultperPage }) => {
  console.log('currentPage, postsPerPage,', currentPage, postsPerPage)
  
  const [currPage, setcurrPage] = useState(currentPage);

  let limit =sessionStorage.getItem("res_per_page");
  if(limit ==null){
    limit = postsPerPage;
  }else{
    limit = parseInt(limit);
  }
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (number) => {
   
    setcurrPage(Number(number));
    paginate(number);
  };
  const handleResultPerPageClick = (val) => {
   
    setcurrPage(1);
    resultperPage(val)
  };
  

  const pages = [];
  
  for (let i = 1; i <= Math.ceil(totalPosts / [limit]); i++) {
    pages.push(i);
  }
  console.log('currPage', currPage, totalPosts, limit, pages, pages[pages.length - 1])
  const indexOfLastItem = currPage * limit;
  const indexOfFirstItem = indexOfLastItem - limit;
  //const currentItems = totalPosts.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number, i) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      //console.log("hello",number)

             return (
       
        <li
          key={i}
          id={number}
         
           className={currPage == number ? "ph-results-pagination-item current" : "ph-results-pagination-item"}
        >
          <a  id={number} onClick={() => handleClick(number)}>{number}</a>
          
        </li>
        
        
      )
     
    }
  });
  
  
  const handleNextbtn = (e) => {

    if(e.currentTarget.id == "pageinreamentbtn"){
      setcurrPage("...inc");
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }else{
      
      
      if (currPage + 1 > maxPageNumberLimit) {
          
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);

      }
      paginate(currPage + 1);
      setcurrPage(currPage + 1);

    }
      
  };

  const handlePrevbtn = (e) => {
    
   
    if(e.target.id == "pagedecreamentbtn"){
      setcurrPage("...dec");
      //if ((currPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      //}
    } else {
      
      //let currentPage = currPage - 1;
     
      
      if ((currPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
      paginate(currPage - 1); 
      setcurrPage(currPage-1);
    }
    
         
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li className="ph-results-pagination-item"   onClick={(e) => handleNextbtn(e)} id="pageinreamentbtn"> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li className="ph-results-pagination-item" onClick={(e) => handlePrevbtn(e)} id="pagedecreamentbtn"> &hellip; </li>;
  }
  console.log('prev in pagination', currPage == "...dec" , currPage !== pages[0] , pages.length !== 1)
  console.log('Next in pagination', currPage == "...inc" , currPage !== pages[pages.length - 1] , pages.length !== 1)
  return (
    <div>
              <div className="row">
                <div className='colo-lg-3'></div>
           <div className="col-md-12 col-lg-9 offset-xl-3 offset-lg-3 search-pagination-main">
    <div className="search-pagination col d-md-block">
    <ul className=" ph-results-pagination">
        <li className="">
          <a
            onClick={handlePrevbtn}
          >
           
            {/* {currPage == pages[0] ? "" : translationUtils.getTranslation(1517,"Prev")} */}
            
            {(currPage == "...dec" || (currPage !== pages[0] && pages.length !== 1)) && translationUtils.getTranslation(1517,"Prev")}
            
          </a>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li className="">
          <a
            onClick={(e) => handleNextbtn(e)}
            disabled={currPage == pages[pages.length - 1] ? true : false}
          >
            {/* {currPage == pages[pages.length - 1] ? "" : translationUtils.getTranslation(717,"Next")} */}
            {(currPage == "...inc" || (currPage !== pages[pages.length - 1] && pages.length !== 1)) && translationUtils.getTranslation(717,"Next")}
            
          </a>
        </li>
      </ul>
  </div>
  <div className="pres-per-page col d-md-block">
              <div className="ph-results-per-page">
                 <span>{translationUtils.getTranslation(621, "Results per page")}:</span>
                 <select  value={limit} name="name-per-page" onChange={(e) => handleResultPerPageClick(e.target.value)}>
                    <option key="10" value="10" >10</option>
                    <option key="20" value="20">20</option>
                    <option key="40" value="40">40</option>
                    <option key="60" value="60">60</option>
                 </select>
                
              </div>
           </div>
  </div>
  </div>
  </div>
  );
};

export default Pagination;