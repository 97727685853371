import React from "react";
import { getNewDomain } from '../../../apiconfig';
const urlParams = new URLSearchParams(window.location.search);
let home = getNewDomain();
const cid = urlParams.getAll('cid');

export default function PageTitle (){
   
    return (
        <div>
           <div className="container-fluid"><div className="ph-header-main__title row px-md-4 px-lg-3">
            <div className="col"><h1 className="p-title">Search </h1></div>
            </div>
            </div>
           <div className="container-fluid"><div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 "><div className="col"><nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item" aria-current="page">
                    <a href={`${home}/us/en/dashboard?cid=${cid}`}>Home</a>
                    </li><li className="breadcrumb-item" aria-current="page">Search</li>
                    </ol></nav></div></div></div>
      
    </div>
    );
}