import { useState } from "react";
export const listFacetType = (ref) => {
  return ref.replace('_facet', '_fq');
};

/**
 * Extract the facet label for use in the UI
 */
export const listLabel = (key) => {
    let label = '',
        hasSep1 = key.includes('/$$$'),
        hasSep2 = key.includes('$$$');

    if (hasSep1) {
        label = key.split('/$$$')[0];

        //If this is a product level greater than one
        //split it first to last instance which would be active selected
        if ((label.match(/\//gi) || []).length > 1) {
            var labels = label.split('/');
            label = labels[labels.length - 1];
        } else {
            label = label.replace(/[/0]/g, '');
        }
    } else if (hasSep2) {
        label = key.split('$$$')[1];
    }

    return label;
};

/**
 *
 * @param String key
 * @returns The data id portion of the facet key (unique to Fusion taxonomies) cleaned up string for use as an attribute
 */
export const listInputValue = (key) => {
    let label = '',
        hasSep1 = key.includes('/$$$'),
        hasSep2 = key.includes('$$$');

    if (hasSep1) {
        label = key.split('/$$$')[1];
    } else if (hasSep2) {
        label = key.split('$$$')[0];
    }

    return label;
};

/**
 *
 * @param Array item
 * @returns A basic slug generator for use as a React element internal ref
 */
export const listElementID = (item) => {
    return item[0].replace(/\s/g, '_');
};

// export const getCategoryType = (category) => {





// }

export const getCategoryType = (category, lang) => {
    /*const types = {
      0: 'products',
      1: 'support',
      2: 'general',
      3: 'news-events'
  };
  const index = allResultTypes[
      `siteSection${language}_facet`
  ].findIndex((facet) => new RegExp(category).test(facet));

  return types[index ?? 0];*/

  //const lang = window.location.pathname.split('/')[1];

  let is_type,
        types = {
            'products': {
				'EN':'p-939117180',
                // 'FI':'p-939117180',
                'FR':'p-939111414',
                'ES':'p952138483',
                'ZH':'p645882',
                'JP':'p1108836',
                'KO':'p1642476',
                'TH':'p-95877730',
                
                'VI':'p356192254',
                'CS':'p-939109486',
                'SV':'p952376500',
                'DK':'p952376500',
                'NO':'p952376500',
                'FI':'p119448614',
                'DE':'p-939109506',
                'IT':'p-939279599',
                'NL':'p952138168',
                'PT':'p-939100998',
                'RU':'p1440520463',
                'HU':'p-1112794284',
                'PL':'p-939109486',
                'TR':'p1464785254'
                
            },
            'support': {
                'EN' : 'p-190113873',
                // 'NL' : 'p-190113873',
                'DE' : 'p-190113873',
                'DK' : 'p-190113873',
                'TH':'p569605064',
                'FR' : 'p-265696456',
                'ZH' :'p828338',
                'JP':'p383886356',
                'KO': 'p1651984',
                // 'TH':'p569605064',
                'TR':'p2043598568',
                'FI':'p763638804',
                'CS':'p1259485203',
                'FI':'p2618303',
                'IT':'p-926378087',
                'NL':'p-926378087',
                'PT':'p-711074182',
                'PL':'p-711074182',
                'PT':'p-190140734',
                'SV':'p-190113873',
                'RU':'p1951998978',
                'VI':'p763638804',
                'HU':'p1017602045',
                'ES': 'p-361915640',
                'NO': 'p-190113873'
            },
           
            'xref': {
                'EN':'p-1526365397',
                'NL':'p-615160939',
                'PL':'p-2082061903',
                'KO':'p-38055327',
                'NO':'p-1449729491',
                'IT':'p1175340919',
                'FI':'p1373181449',
                'VI':'p215459153',
                'HU':'p-828039456',
                'DK':'p-1267137398',
                'ES':'p-1404004964',
                'PT':'p-1771899231',
                 'SV':'p-20305393',
                 'TH':'p-877837782',
                 'TR':'p1747663857',
                 'JP':'p-1359765957',
                 'ZH':'p621062374',
                 'FR':'p1389145609',
                 'CS':'p1275118854'
            },
        };

        let getkey = false;

    for (var key in types) {
        if (types.hasOwnProperty(key)) {
            //is_type = types[key][lang] == category ? key : false;
             console.log("istype",key, types[key][lang], category)
            if (types[key][lang] == category) return key;
            
        }
        
    }

    // console.log("getkey",getkey)
    // return getkey;

};

export const categoriesOrder = {
    'support': 1,
    'products': 2,
    'xref': 3,
};

export const getPid = (key) => {

    let dynamicPids = '';
   
    dynamicPids = key.split('$$$')[0];
      
    return dynamicPids;
  };

 export const getTabs = (resultTypeKey) => {
    const lang = window.location.pathname.split('/')[1];
    let resultTypes = [];
    let allResultTypes1 = JSON.parse(sessionStorage.getItem("facetFields"));
  

    if (allResultTypes1) {
      if (resultTypeKey in allResultTypes1) {
          if (allResultTypes1[resultTypeKey].length) {
              resultTypes = allResultTypes1[resultTypeKey];
          }
      }
  }
    return resultTypes.map((item) => ({
            item,
            order: categoriesOrder[getCategoryType(listInputValue(item[0]), lang)],
        }))
        .sort((a, b) => a.order - b.order)
        .map((obj) => obj.item);
   

};


export const getTabs2 = (resultTypeKey, myFacets) => {
    console.log("myFacets",myFacets)
    const lang = window.location.pathname.split('/')[1];
    let resultTypes = [];
    let allResultTypes1 = myFacets;
  

    if (allResultTypes1) {
      if (resultTypeKey in allResultTypes1) {
          if (allResultTypes1[resultTypeKey].length) {
              resultTypes = allResultTypes1[resultTypeKey];
          }
      }
  }


 console.log("restype",resultTypes)
    let ss = resultTypes.map((item) => ({
            item,
            order: categoriesOrder[getCategoryType(listInputValue(item[0]), lang)] || 4,
        }))
        console.log('ss after order', ss)
        ss = ss.sort((a, b) => a.order - b.order)
        console.log('ss after sort', ss)
        ss = ss.map((obj) => obj.item);
        console.log('ss after map', ss)
        console.log("ordered1facet1",ss)

        return ss;  

};
