import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Breadcrumb from './Breadcrumb';
import './assetsCategories.css';

import { Route } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { getAPIAuthKey, getHostForDA } from '../../apiconfig';

let url = new URL(window.location.href);
let defaultLang = url.pathname.split('/')[1];
console.log('lang===========', defaultLang);

if(defaultLang=='' || defaultLang==null){
defaultLang = window.sessionStorage.getItem('defaultLang') || "EN";
}
//let defaultLang = window.sessionStorage.getItem('defaultLang');
let distributorId = window.sessionStorage.getItem('distributorId');

const NewSupportPage = (props) => {
  const [tiles, setTiles] = useState([]);
  const [supportTypes, setSupportTypes] = useState([]);
  const supportImgArray = [];
  const supportTypesArray = [];

 
  // first we will get the image url and category name

  const getImgCatName = async () => {
    try {
      const response = await axios.get(
        getHostForDA() +
          `/solr/MyParker/supportHome/select?json.nl=arrarr&wt=json&1.q=coreName_s:CategoryData&1.fq=LangCode_s:${props.lang.toUpperCase()}&2.fq=languageCode_ss:(EN)%20AND%20(customerId_ss:all)&2.fq=includeCountry_ss:(DEU)&3.fq=langCode_s:${props.lang.toUpperCase()}&4.facet.field=siteSection${props.lang}_facet&5.fq=customerId_s:00`,
        {
          headers: {
            'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
          },
        }
      );

      const facetsTile = response.data['3_response'] || [];
      for (let i = 0; i < facetsTile.docs.length; i++) {
        const catTile = facetsTile.docs[i]['supportCatTitle_si']; // The hash value
        const catImg = facetsTile.docs[i]['supportCatImage_si']; // The hash value

        if (facetsTile.docs) {
          const tile = {
            tilieTitle: catTile, // The first part (pid)
            tileImage: catImg, // The second part (pname)
          };
          supportImgArray.push(tile);
        }
      }
      console.log('Tiles====', supportImgArray);
      setTiles(supportImgArray);
      fetchData(supportImgArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // This is used for fetech hashcode and category name.
  const fetchData = async (tileArray) => {
    const facetTransParams = tileArray
      .map((tile) => encodeURIComponent(tile.tilieTitle))
      .join('&facetTrans=');
    try {
      const response = await axios.get(
        getHostForDA() +
          `/fusionsearch/apollo/collections/ParkerDotComMain/query-profiles/parkerdotcom-hash/select?echoParams=all&wt=json&json.nl=arrarr&debug=false&rows=0&facetTrans=${facetTransParams}`,

        {
          headers: {
            'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
          },
        }
      );

      const facets = response.data.responseHeader.params.hashes || [];

      for (let i = 0; i < facets.length; i++) {
        const fq1 = facets[i]; // The hash value

        // Split the hash value into pid and pname
        const [pid, pname] = fq1.split('$$$');

        if (fq1) {
          const tile = {
            fq: pid, // The first part (pid)
            heading: pname, // The second part (pname)
          };
          supportTypesArray.push(tile);
        }
      }

      setSupportTypes(supportTypesArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getImgCatName();
    // fetchData();
  }, []);

  // Merging newArray and oldArray based on heading and title

  const supportTiles = supportTypes.map((newItem) => {
    const oldItem = tiles.find(
      (oldItem) => oldItem.tilieTitle === newItem.heading
    );
    return {
      fq: newItem.fq,
      heading: newItem.heading,
      ...(oldItem ? { image: oldItem.tileImage, link: oldItem.link } : {}),
    };
  });
  //console.log('Final Array==', supportTiles);
  // Log the result

  const constructSearchUrl = (fq, title) => {
    const existingParams = new URLSearchParams(window.location.search);
    existingParams.set('supportType' + props.lang.toUpperCase() + '_fq', fq); // Add or update the fq parameter
    existingParams.set('searchbox', '*'); // Add or update the fq parameter
    return `search?${existingParams.toString()}`; // Construct the new URL
  };
  return (
    <>
      <Breadcrumb />
      <div className='container products-categories-container'>
        <h1>Support Asset Categories</h1>
        <Container>
          <div className='row'>
            {supportTiles.map((tile, index) => (
              <div className='col-md-4 support-tile' key={index}>
                <Link
                  to={constructSearchUrl(tile.fq, tile.heading)}
                  class='left support-heading-block'
                  tabindex='0'>
                  <p className='tileHeading'>{tile.heading}</p>
                </Link>
                <div class='support-image'>
                  <img
                    src={
                      tile.image
                        ? tile.image
                        : 'https://www.parker.com/content/dam/Parker-com/Controlled-Documents/Support/Support-Category-images/compliance-certifications-safety1.gif'
                    }
                    height='100'
                    width='100'
                    alt='Support'
                    title={tile.heading}
                  />
                </div>{' '}
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewSupportPage;
